@import "~antd/dist/antd.css";

@import "./assets/scss/common.css";

.list-role-actions {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

body {
  margin: 0;
  font-family: "Arimo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ql-container.ql-snow {
  height: 700px;
}

h2,
h3,
h4 {
  padding: 8px 0 !important;
  font-weight: 500 !important;
}
.ck-content {
  height: 600px;
}
.ant-tag {
  margin: 0 8px 8px 0 !important;
}

#root {
  height: 100vh;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.font-medium {
  font-weight: 500;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.py-2\.5 {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: #28b6f6;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.block {
  display: block;
}
.me-2 {
  margin-inline-end: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.text-red-500 {
  color: red;
}
.content {
  display: flex;
}
.wrap-content {
  flex: 1;
}
.banner {
  margin-top: 50px;
  text-align: center;
  /* position: fixed;
  top: 0;
  z-index: 9999999; */
  /* left: 50%;
  transform: translateX(-50%); */
}
.banner > img {
  margin: 0 auto;
  /* height: 100px; */
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}
.scrolling-text {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  animation: scrollText 5s linear infinite;
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
